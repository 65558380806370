$left-padding: 1em;
$black: #333366;
$bottom-bar-height: 3em;
$font-family: Roboto, sans-serif;
$link-color: #35b2dc;
$heading-color: $link-color;
$heading-height: 2.5em;

* {
  box-sizing: border-box;
}

html {
  font-size: min(4vw, 3vh);
  font-family: $font-family;
  color: $black;
}

h2 {
  font-size: 1.3em;
}

input {
  font-size: 1.1rem;
}

textarea {
  font-size: 1rem;
  font-family: $font-family;
}

button {
  @extend .dropshadow;
  cursor: pointer;
  font-size: 1rem;

  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  background-color: $heading-color;
  color: white;

  padding: 0.3em 2em;
  max-height: 2em;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    color: #ffffff88;
  }

  &.delete:not(:disabled) {
    background: red;
  }

  &.complete:not(:disabled) {
    background: green;
  }

  &.share:not(:disabled) {
    background: $link-color;
  }
}

body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  header {
    @extend .dropshadow;
    position: fixed;
    z-index: 2;
    width: 100%;
    display: flex;
    padding: 0 $left-padding;
    background: $heading-color;
    color: white;
    height: $heading-height;
    > * {
      margin-top: 0.2rem;
    }
    h1 {
      flex-grow: 1;
      margin: 0.3em 0;
      font-size: 1.4em;
      margin-bottom: 0.4em;
    }
    .logged-in-info {
      font-size: 0.5rem;
      * {
        vertical-align: top;
      }
      .logout {
        color: white;
        margin-left: 1em;
        text-decoration: underline;
      }
      .sync-status {
        .symbol {
          margin-left: 0.5em;
          transition: all 0.5s;
          cursor: pointer;
          font-size: 2.5em;
        }
        &.up-to-date .symbol {
          color: #4aea4a;
          font-size: 1em;
        }
        &.offline .symbol,
        &.sync-pending .symbol {
          @extend .textshadow;
          color: #ed4444;
        }
      }
    }
  }
}

.dropshadow {
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.16);
}
.dropshadow-shallow {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.16);
}

.textshadow {
  text-shadow: -1px 2px 6px rgba(0, 0, 0, 0.3);
}

a {
  color: $link-color;
  cursor: pointer;
}

#shopping {
  margin-top: $heading-height;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1em;
  .lists {
    padding: 0 1em;
  }
  &.shopping {
    .shopping-list:not(.shopping) {
      max-height: 0;
      opacity: 0;
      border: none;
      padding: 0;
      pointer-events: none;
    }
  }
  .editable-row-with-buttons {
    display: flex;
    .name {
      width: 100%;
      &:not([contenteditable="true"]) {
        cursor: pointer;
      }
    }
    .controls {
      display: flex;
      font-size: 0.7em;
      margin-left: 1em;
      button {
        margin-right: 0.5em;
        padding: 0.3em 1em;
      }
    }

    .name[contenteditable="true"] {
      padding: 0 0.1em;
    }
  }
  .shopping-list {
    transition: max-height 0.5s, opacity 0.5s;
    max-height: 100rem;
    h2 {
      @extend .editable-row-with-buttons;

      a.shopping {
        margin-left: 0.7rem;
        & :first-of-type {
          margin-left: 1em;
        }
        font-size: 1rem;
        white-space: nowrap;
      }

      .icon.shopping {
        vertical-align: top;
        margin-right: 0.4em;
        margin-top: 0.05em;
        background-image: url("img/shopping_cart-24px.svg");
      }

      .icon.settings {
        vertical-align: top;
        margin-top: 0.05em;
        margin-left: 0.2em;
        font-size: 0.7em;
        cursor: pointer;
        background-image: url("img/settings-24px.svg");
      }
    }
    .list-settings {
      font-size: 0.7em;
      margin-bottom: 1em;
      margin-top: -1em;
    }
    .share {
      .icon {
        background-image: url("icon-share.svg");
        display: inline-block;
        height: 0.5em;
        width: 0.5em;
        margin-left: 0.2em;
        cursor: pointer;
      }
    }
    &.shopping .item {
      font-size: 1.5em;
    }
    .category:not(:first-of-type) {
      margin-top: 0.5em;
    }
    .department {
      margin-bottom: 0.5em;
      font-size: 0.6em;
      border-bottom: 1px solid #cccccc;
    }
    .item {
      @extend .editable-row-with-buttons;
      margin-bottom: 0.2em;
      transition: font-size 0.2s;

      .checkbox {
        margin-right: 0.4em;
      }

      .name {
        text-transform: capitalize;
      }

      .name[contenteditable="true"] {
        font-size: 1.5em;
      }

      .location {
        font-size: 0.6em;
        white-space: nowrap;
        opacity: 0.8;
      }

      &.checked .name {
        text-decoration: line-through;
        opacity: 0.5;
      }
    }
    > .placeholder {
      display: flex;
      margin-top: 0.5em;
      $input_height: 3rem;
      .input-area {
        width: 100%;
        display: inline-block;
        position: relative;
        input {
          width: 100%;
        }
        .autocomplete {
          text-transform: capitalize;
          z-index: 1;
          position: absolute;
          top: $input_height;
          left: 0;
          width: 100%;
          box-sizing: border-box;
          background: white;
          padding: 2px;
          padding-right: 1em;
          border-bottom: 1px solid #888888;
          border-left: 1px solid #888888;
          border-right: 1px solid #888888;
          .item {
            cursor: pointer;
          }
        }
      }

      button {
        margin-left: 0.3em;
      }
      &.editing {
        font-size: 1.5rem;
        input {
          height: $input_height;
          font-size: 1.5rem;
        }
      }
    }
    > .suggestions {
      margin-top: 0.5em;
      > a {
        @extend .dropshadow-shallow;
        &:first-of-type {
          margin-left: 0.6em;
        }
        text-transform: capitalize;
        background-color: $link-color;
        color: #fff;
        font-size: 0.8em;
        margin-left: 0.3em;
        padding: 0.1em 0.5em;
        border-radius: 1em;
        display: inline-block;
        margin-bottom: 0.3em;
        .icon {
          vertical-align: text-top;
          display: inline-block;
          margin-left: 0.2em;
          font-size: 1.2em;
        }
      }
      &.empty {
        display: none;
      }
    }

    &:not(:last-of-type) {
      padding-bottom: 1em;
      border-bottom: 1px solid #789eab;
    }
  }
}

#bottom-bar {
  width: 100%;
  padding-top: 0.5em;
  padding-left: $left-padding;
  > button {
    background: none;
    color: $link-color;
    padding-right: 1.5em;
    font-size: 1em;
    padding: 0;
    > .icon {
      margin-right: 0.2em;
    }
    box-shadow: none;
  }
  padding-bottom: 0.5em;
}

.icon {
  display: inline-block;
  width: 1em;
  min-width: 1em;
  height: 1em;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon.delete {
  background-image: url(img/cross-2.svg);
  cursor: pointer;
}

.icon.add {
  vertical-align: top;
  margin-right: 0.4em;
  margin-top: 0.05em;
  background-image: url("img/plus-circled.svg");
}

.checkbox {
  @extend .icon;
  background-image: url(img/unchecked-checkbox.png);
  cursor: pointer;
  &.checked {
    background-image: url(img/checked-checkbox.png);
  }
  width: initial;
  min-width: 1em;
  padding-left: 1.5em;
  color: $black; /* to prevent link-like coloring for contained text */
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-content {
    min-height: 10em;
    display: flex;
    flex-direction: column;
    input,
    button,
    textarea,
    p {
      display: block;
      min-width: 10em;
      max-width: 20em;
      margin: 0;
      margin-bottom: 1em;
    }
    input[type="email"] {
      font-size: 1em;
      min-width: 15em;
    }
    small {
      margin-top: -0.5em;
      margin-bottom: 1em;
      font-size: 0.8em;
      opacity: 0.7;
    }
  }
}

.recipe-view {
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .backLink {
      font-size: 0.7em;
    }
  }
  
  .header {
    display: flex;
    gap: 0.5em;
    input {
      flex-grow: 1;
    }
  }
  .ingredients {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    .amount {
      opacity: 0.5;
      float: right;
    }
  }
  .addToList {
    margin-top: 1em;
  }
  margin-bottom: 1em;
}